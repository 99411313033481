import { useState } from 'react';
import { ToggleCollapse, ToggleCollapseProps } from './ToggleCollapse';

export type ToggleCollapseContainerProps = Omit<ToggleCollapseProps, 'handleSetOpen' | 'isOpen'> & {
    isOpenDefault: boolean;
};

export const ToggleCollapseContainer = ({
    children,
    isDisabled,
    isOpenDefault,
    title,
}: ToggleCollapseContainerProps): JSX.Element => {
    const [open, setOpen] = useState(isOpenDefault);

    return (
        <ToggleCollapse
            handleSetOpen={() => setOpen(prev => !prev)}
            isDisabled={isDisabled}
            isOpen={open}
            title={title}
        >
            {children}
        </ToggleCollapse>
    );
};

ToggleCollapseContainer.displayName = 'ToggleCollapseContainer';
